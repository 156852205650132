<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import appConfig from "@/app.config";

export default {


    props: {

    },
    components: {
        Layout,
        PageHeader,
        VuePdfApp
    },

    page: {
        title: "Deal Overview",
        meta: [
        {
            name: "description",
            content: appConfig.description,
          
        },
        ],
    },

    data() {

        return {
            title: "Deal Overview",
            items: [
                {
                text: "Deal",
                },
                {
                text: "Overview",
                active: true,
                },
            ],

            current_file: {},
            file_name: '',
            preview_file_url: 'http://localhost:8080/adm/misc/deal_file_download?url=https://mobile-realty.s3.us-west-2.amazonaws.com/dev/documents/public/vwPnX2SK-bank_reconciled_stmt_p1.pdf',
            pdf_previe_config: {

                secondaryToolbar: false,
                sidebar: false,

                toolbar: {
                    toolbarViewerLeft: {
                        findbar: false,
                        previous: true,
                        next: true,
                        pageNumber: true,
                    },

                    toolbarViewerRight: {
                        presentationMode: false,
                        openFile: false,
                        print: true,
                        download: true,
                        viewBookmark: false,
                    },
                }
            },

            target : null,

            ctx : null,
            sign_img : new Image(100, 100),
            sign_img_boxs : [],
            zoom_box_size  : 10
        }

    },


    computed: {
        btn_disable_flag() {
            return this.deal.order_state == 'CLOSED' || this.deal.order_state == 'COLLAPSED'
        }
    },

    methods: {




        onPdfError(e) {
            console.log(e)
        },

        drawImageBox(context, sign_img_box_obj, x, y) {

            let zoomBox = this.zoom_box_size
            let width = sign_img_box_obj.width;
            let height = sign_img_box_obj.height;

            context.drawImage(this.sign_img, x, y, width, height);
            
            context.rect(x , y,  width,  height)
            context.stroke()

            context.rect(x - zoomBox / 2 , y -  zoomBox / 2,  zoomBox,  zoomBox)
            context.stroke()

            context.rect(x + width - zoomBox / 2 , y  - zoomBox / 2,  zoomBox, zoomBox)
            context.stroke()

            context.rect(x - zoomBox / 2  , y + height - zoomBox / 2 , zoomBox,  zoomBox)
            context.stroke()

            context.rect(x + width - zoomBox / 2  , y + height - zoomBox / 2 ,  zoomBox,  zoomBox)
            context.stroke()

           
        },

        openHandler(pdfApp) {

            pdfApp.pdfViewer.pdfDocument.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport({ scale: scale, });
                // Support HiDPI-screens.
               // var outputScale = window.devicePixelRatio || 1;

              
                var canvas = document.createElement('canvas');
               
                var context = canvas.getContext('2d');

                
                this.sign_img.src = "http://localhost:8080/img/logo-light.c3a4d005.svg";
                this.sign_img.addEventListener("load", () => {
                    let sign_img_box_obj = {
                        img : this.sign_img,
                        x  : 0,
                        y  : 0,
                        width : 200,
                        height : 100,
                        mode : 'None'
                    }
                    this.sign_img_boxs.push(sign_img_box_obj)
                    this.drawImageBox(context, sign_img_box_obj, 30, 30)
                });
                

                canvas.width = Math.floor(viewport.width);
                canvas.height = Math.floor(viewport.height );
                canvas.style.width = Math.floor(viewport.width) + "px";
                canvas.style.height = Math.floor(viewport.height) + "px";
                canvas.style.position = "absolute";
                canvas.style.left = "0px";
                canvas.style.top = "0px";
                canvas.style.zIndex = "100";

                var container = document.getElementById('viewerContainer')
                container.appendChild(canvas)

               
              

               
               // context.fill()

                canvas.addEventListener('mousedown', this.handleMouseDown);
                canvas.addEventListener('mousemove',this.handleMouseMove);
                canvas.addEventListener('mouseup', this.handleMouseUp);
                canvas.addEventListener('mouseout', this.handleMouseOut);

                this.ctx = context

            })
        },

        handleMouseDown(e) {
            this.sign_img_boxs.map(b => {

                if (b.x  < e.layerX && b.x + b.width >= e.layerX &&
                    b.y  < e.layerY && b.y + b.height >= e.layerY) {
                   

                    if (b.x +  b.width - this.zoom_box_size /2 < e.layerX && b.x + b.width + this.zoom_box_size /2 > e.layerX) {
                        document.body.style.cursor = 'ew-resize'
                        b.mode = 'Zoom-X'
                    } else if (b.y + b.height - this.zoom_box_size /2 < e.layerY && b.y+b.height + this.zoom_box_size /2 > e.layerY) {
                        document.body.style.cursor = 'ns-resize'
                        b.mode = 'Zoom-Y'
                    } else {
                        b.mode = 'Drag'
                    }
                    console.log(b.mode, b.x, b.y, b.width, b.height, e.layerX, e.layerY)

                    //
                    //TODO check is zoom or drag
                } else {
                    b.mode = 'None'
                }
            })
        },


        handleMouseMove(e) {
            this.ctx.reset()
            this.sign_img_boxs.map(b => {
                if (b.mode == 'Drag') {
                    this.drawImageBox(this.ctx, b, e.layerX, e.layerY)
                    b.x = e.layerX
                    b.y = e.layerY
                } else if (b.mode == 'None') {
                    this.drawImageBox(this.ctx, b, b.x, b.y)
                }
            })

        },

        handleMouseUp() {
            this.sign_img_boxs.map(b => {
                 b.mode = 'None'
            })
            document.body.style.cursor = 'default'
        },

        handleMouseOut() {
            document.body.style.cursor = 'default'
        },

        created() {


        },

        mounted() {

        }
    }

};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row" style="min-height: 70dvh;">
            <div class="col-md-12">
                <vue-pdf-app :config="pdf_previe_config" :pdf="preview_file_url" @open="openHandler"></vue-pdf-app>
            </div>
        </div>
        <!-- end row -->

    </Layout>
</template>